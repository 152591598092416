import cs from 'classnames';
import { useEffect, useState } from 'react';
import { Swiper } from 'antd-mobile';
// import Collapse from '@/components/Collapse';
import { useNavigate } from 'react-router-dom';
import {
  ABOUT_US as as,
  APPROACH as appr,
  SCIENCE as scie,
  NEWS as news,
  VISION,
  CAREERS as care,
  CONTACT
} from '@/utils/data';
import './index.scss';


const Home = () => {
  const [apprDesc1, apprDesc2] = appr.desc;
  const [technology, solution] = scie.desc;
  const techSourceData = technology.desc.map((t, index) => {
    const id = ['intelligent', 'deep', 'translational', 'digital'][index];
    const imgUrl = [
      require('@/assets/images/0.png'),
      require('@/assets/images/1.png'),
      require('@/assets/images/2.png'),
      require('@/assets/images/3.png')
    ][index];
    return {
      id,
      imgUrl: imgUrl,
      title: t.title,
      content: t.desc
    }
  })
  const [VALUES, CULTURE] = care.desc;
  const [valueIndex, setValueIndex] = useState<number>(-1);
  const [isShowSc, setIsShowSc] = useState('');
  const navigate = useNavigate();
  const [isShowTip, setIsShowTip] = useState(false)

  const isCN = window.location.host.search('.cn') > -1;


  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShowTip(true);
    }, 500);

    // return () => {
    //   clearTimeout(timer);
    // }
  }, [])

  return (
    <section className='lf-container'>

      {/* ABOUT US */}
      <header className='lfc-about'>

        <h1>{as.title}</h1>

        <section>{as.desc}</section>

        <footer>
          <div onTouchStart={() => {
            navigate('/about')
          }}>{as.btn}</div>
        </footer>

        <ul className={cs('lfc-about-icon', { 'lfc-about-icon-tip': isShowTip })}>
          <li><img src={require('@/assets/images/about-1.png')} alt="" /></li>
          <li><img src={require('@/assets/images/about-2.png')} alt="" /></li>
          <li><img src={require('@/assets/images/about-3.png')} alt="" /></li>
        </ul>

      </header>

      {/* Approach */}
      <section id='approach' className='lfc-appr'>

        <h2>{appr.title}</h2>

        <section className='lfc-appr-before'>
          <header>{apprDesc1.title}</header>
          <section>{apprDesc1.desc}</section>
        </section>

        <section className='lfc-appr-after'>
          <header>{apprDesc2.title}</header>
          <section>{apprDesc2.desc}</section>
        </section>

      </section>

      {/* TECHNOLOGY */}
      <section id='technology' className='lfc-tech'>

        <header>
          <h2>{scie.title}</h2>
          <h3>{technology.title}</h3>
        </header>
        {/* <Collapse defaultId='intelligent' sourceData={techSourceData} /> */}

        <section className='lfc-tech-container'>
          <ul className='lfc-tech-container-wrapper'>
            {
              techSourceData.map((t, index) => {

                return (
                  <li className='lfc-tech-container-content' key={`tech_${t.id}_${index}`}>
                    <header onTouchStart={() => {
                      if (t.id == isShowSc) {
                        return setIsShowSc('');
                      }
                      setIsShowSc(t.id);
                    }}>
                      <img src={t.imgUrl} alt="" />
                      <p>{t.title}</p>
                    </header>

                    <section className={cs({ 'selected': t.id == isShowSc })}>
                      <div>{t.content}</div>
                    </section>
                  </li>
                )
              })
            }
          </ul>
        </section>

      </section>

      {/* SOLUTION */}
      <section id='solution' className='lfc-solution'>

        <header>
          <h2>{scie.title}</h2>
          <h3>{solution.title}</h3>
        </header>

        <section className='lfc-solution-content'>
          {
            solution.desc.map((sol, index) => (
              <div className='lfc-solution-content-item' key={`solution_${index}`}>
                <img src={require(`@/assets/images/solution/${sol.desc}.png`)} alt="" />
                <p>{sol.title}</p>
              </div>
            ))
          }
          <img src={require('@/assets/images/solution/content.png')} alt="" />
        </section>

      </section>

      {/* NEWS */}
      <section id='news' className='lfc-news'>
        <Swiper
          slideSize={70}
          trackOffset={15}
          autoplay
          loop
          indicator={() => null}
          className='lfc-careers-swiper'
        >
          {
            news.desc.map((n, index) => {

              return (
                <Swiper.Item
                  key={`new_${index}`}
                  className={`lfc-news-item item-${index}`}
                >
                  <a href={n.href}>{n.title}</a>
                </Swiper.Item>
              )
            })
          }
        </Swiper>
      </section>

      {/* VISION */}
      <section id='vision' className='lfc-vision'>
        <img src={require('@/assets/images/header.png')} alt="" />
        <header>{VISION.title}</header>
        <p>{VISION.desc}</p>
      </section>

      {/* CAREERS VALUES */}
      <section id='careers' className='lfc-careers-header'>
        <h2>{care.title}</h2>
        <h3>{VALUES.title}</h3>
      </section>
      <section id='values' className='lfc-values'>
        {
          VALUES.desc.map((value: any, index) => {

            if (valueIndex == index) {
              return (
                <div
                  onTouchStart={() => {
                    setValueIndex(-1);
                  }}
                  className='lfc-values-item lfc-values-item-content'
                  key={`value_${index}`}
                >
                  <h3>{value.title}</h3>
                  <p>{value.desc}</p>
                </div>
              )
            }

            return (
              <div
                onTouchEnd={() => {
                  setValueIndex(index);
                }}
                className='lfc-values-item' key={`value_${index}`}
              >
                <img src={require(`@/assets/images/${value.imgUrl}`)} alt="" />
                <p>{value.title}</p>
              </div>
            )
          })
        }
      </section>

      {/* CAREERS */}
      <section id='culture' className='lfc-careers-header'>
        <h2>{care.title}</h2>
        <h3>{CULTURE.title}</h3>
      </section>
      <section id='careers' className='lfc-careers'>
        <Swiper autoplay loop indicator={() => null} className='lfc-careers-swiper'>
          {
            CULTURE.desc.map((c, index) => {

              return (
                <Swiper.Item
                  className='lfc-careers-swiper-item'
                  key={`careers_${index}`}

                >
                  <section>
                    <header>{c.title}</header>
                    <p>{c.desc}</p>
                  </section>
                </Swiper.Item>
              )
            })
          }
        </Swiper>
      </section>

      {/* CONTACT US */}
      <section id='contact' className='lfc-contact'>
        <header>{CONTACT.title}</header>
        <section>
          <div className='lfc-li'>
            <img src={require('@/assets/images/partnership.png')} alt="" />
            <p>{CONTACT.ship}</p>
          </div>
          <div className='lfc-li'>
            <img src={require('@/assets/images/hr.png')} alt="" />
            <p>{CONTACT.email}</p>
          </div>
          <div className='lfc-li'>
            <img src={require('@/assets/images/address.png')} alt="" />
            <p>{CONTACT.address}</p>
          </div>
          <div className='lfc-li'>
            <img src={require('@/assets/images/address.png')} alt="" />
            <p>{CONTACT.addressNew}</p>
          </div>
        </section>
        <img src={require(`@/assets/images/map.png`)} alt="address" />
      </section>

      <footer className='home-about-footer'>
        ©2022 Leaflong Therapeutics Co. Ltd. All Rights Reserved.
        <div className='home-about-ban'>
          <a href='https://beian.miit.gov.cn/' target='_blank'>{isCN ? '浙ICP备2022032937号-1' : '沪ICP备2022009719号-1'}</a>
        </div>
      </footer>

    </section>
  )
}

export default Home;