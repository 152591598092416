import './index.scss';

const About = () => {

  return (
    <section className='lyx-about-wrapper'>
      <div>
        <img src={require('@/assets/images/about01.png')} alt="" />
      </div>
      <p>
      Leaflong Therapeutics is a pioneer in redefining medical treatment with prescription digital therapeutics and data driven patient care in disease areas related to cigarette usage. Our cross-functional team is comprised of global medical academic leaders, algorithm scientists and geneticist. We operate at the intersection of medicine, AI technology and bioinformatics. Leaflong aspires to explore, develop, and deliver clinically validated, individualized, and affordable medical services to patients in need in a frictionless fashion
      </p>
      <div>
        <img src={require('@/assets/images/about02.png')} alt="" />
      </div>
      <p>
      Unlike many peers who try to provide treatment that is independent from traditional medical system, Leaflong works closely with public and private healthcare institutions by providing best-in-class AI-driven clinical data platform across multiple indications that help doctors and nurses in every aspect from medical practice efficiency, clinical data processing, to patient health monitoring. Through collaborative research and development, we create first-in-class diagnosis, treatment and care products with the best respirologist, cardiologist, gastroenterologist, and oncologists who draft the Chinese and global clinical guidelines among various indications
      </p>
    </section>
  )
}

export default About;