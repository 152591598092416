
import { Outlet } from "react-router-dom";
import Header from '@/components/Header';
import './index.scss';

const Layout = () => {

  return (
    <div className='lf-layout-wrapper'>

      <Header />

      <Outlet />

    </div>
  )
}

export default Layout;