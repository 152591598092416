


// ABOUT US
export const ABOUT_US = {
  title: 'ABOUT US',
  desc: 'Redefining diagnosis, treatment, and care with prescription digital therapeutics (PDT), big data driven patient management and next-generation-sequencing (NGS) in disease areas related to cigarette use disorder',
  btn: 'READ MORE'
}

// Approach
export const APPROACH = {
  title: 'Approach',
  desc: [
    {
      title: 'Intelligent Information Processing',
      desc: "We believe that Intelligent Information Processing is to direct people's attention on the things that are useful and away from those that distract. To apply that notion in today's healthcare, we strive to provide patients with engagement maximizing solutions in conjunction with cognitive behavior therapy in order to subvert the lazy human nature, which is the number one hurdle in chronic disease home management"
    },
    {
      title: 'Sky is the Limit',
      desc: "We start off with experienced multidisciplinary industry veterans, who are healthcare authorities on many levels, combining data and experience to build an AI platform that would translate advanced medicine into patient welfare and business value. We entitle the engine to iterate autonomously, and its stakeholders the freedom to generate and implement arbitrary protocols, then wait to see what greatness could emerge"
    }
  ]
}

// SCIENCE
export const SCIENCE = {
  title: 'SCIENCE',
  desc: [
    {
      title: 'TECHNOLOGY',
      desc: [
        {
          title: 'Intelligent EMR Processing Database',
          desc: 'We conduct in-depth structured extraction, analysis, and inference of EMR through relationship extraction, event extraction, knowledge chain and more, along with refined valuable information from unstructured data filtered by academic leaders to support the construction of special disease database'
        },
        {
          title: 'Deep Structure Chronic Disease Management System',
          desc: 'We establish standards for special disease clinical research data, and treatment and care pathways under the guidance of global academic leaders by following national medical data security policy and institutional compliance. Our system is highly compatible with external ports that supports multi-source-data (HIS, LIS, PACS, EMRs) and multi-data-center connection and sharing, which allows us to provide the patients with a close-loop disease management eco-system， in conjunction with medicine, that offers seamless user experience for home follow-up, care, monitoring and more for diverse cohorts'
        },
        {
          title: 'Translational Medicine Platform',
          desc: 'We bridge across the discovery, development, regulation, and utilization spectrum. We apply research findings from genes, proteins, cells, tissues, to clinical research in patient populations, all aimed at optimizing and predicting outcomes in patients with cigarette use disorder. Especially, we leverage bioinformatics to provide patients with a complete chronic disease management eco-system from disease screening, diagnostics to treatment and care'
        },
        {
          title: 'Digital Biomarkers Lab',
          desc: 'We track objective, quantifiable physiological and behavioral data that is collected and measured by in-house designed portable, expiratory or implantable digital devices. We run millions of tests on our database every year to discover and apply digital biomarkers that represent opportunities to capture data that is clinically meaningful, objective, and accessible with low friction. With our AI model analysis, the digital biomarkers collected are typically used to explain, influence and/or predict special-disease-related therapeutic outcomes'
        }
      ]
    },
    {
      title: 'SOLUTION',
      desc: [
        {
          title: 'RESPIROLOGY',
          desc: 'respirology'
        },
        {
          title: 'CARDIOLOGY',
          desc: 'cardiology'
        },
        {
          title: 'GASTROENTEROLOGY',
          desc: 'gastroenterology'
        },
        {
          title: 'ONCOLOGY',
          desc: 'oncology'
        }
      ]
    }
  ]
}

// NEWS
export const NEWS = {
  title: 'MORE',
  desc: [
    {
      title: 'World No Tobacco Day ｜ Tabacco Threatens the Environment，Smoking Endangers Lives',
      desc: '',
      imgUrl: 'new0',
      href: 'https://mp.weixin.qq.com/s/wFgLshFTzbjfl1-xlbTvCw',
    },
    {
      title: 'Focusing on the Digital Therapeutics in Disease Areas Related to Cigarette Usage, Leaflong Therapeutics Appeared at the Cross Strait Respiratory…',
      desc: '',
      imgUrl: 'new2',
      href: 'https://www.vbdata.cn/54609',
    },
    {
      title: 'Digital Therapeutics Industry Research Report 2022',
      desc: '',
      imgUrl: 'new1',
      href: 'https://mp.weixin.qq.com/s/s-5BnMiJLZJTimyoX7Ar_A',
    }
  ]
}

// VISION
export const VISION = {
  title: 'VISION',
  desc: 'Stop tobacco from destroying our lives'
}

// CAREERS
export const CAREERS = {
  title: 'CAREERS',
  desc: [
    {
      title: 'OUR VALUES',
      desc: [
        {
          title: 'Innovation',
          imgUrl: 'c1.png',
          desc: 'We are passionate about making difference and impact with safe and effective digital therapies to patients in need.'
        },
        {
          title: 'Performance',
          imgUrl: 'c2.png',
          desc: 'Our ability to invent and apply new technologies evolve as we gain in-depth understanding of the a diverse cohorts through our services. '
        },
        {
          title: 'Ownership',
          imgUrl: 'c3.png',
          desc: 'We never lose sight of why our work matters – the potential to impact the lives of patients is important to everyone at Leaflong.'
        },
        {
          title: 'Curiosity',
          imgUrl: 'c4.png',
          desc: 'We are always at the state of curiosity. We believe it is the intellectual curiosity that set us to drive and solve problems.'
        }
      ]
    },
    {
      title: 'OUR CULTURE',
      desc: [
        {
          title: 'Knowledge has No Limit',
          desc: 'The pace of change and velocity of the research and development of digital therapeutics force us to stay hungry and stay foolish and continuously pick up new knowledge, experiment with new technologies. Having the best scientists, doctors, and developers on board, we are committed in pursuit of our goal to explore the most advanced digital medicine and translate them into novel therapeutic products.'
        },
        {
          title: 'Strive for Excellence',
          desc: 'As we push the bounds of scientific exploration, we understand there will be obstacles, setbacks, and even failure. Despite how the road of digital therapeutics twist and turns, every Leaflong member remains resilient and supportive of one another. Strive for excellence, innovate for the future. Leaflong employees will remain dedicated and committed to the pursuit of excellence and innovation. '
        },
        {
          title: 'Smile with Optimism',
          desc: "At Leaflong, there's life at work and life outside of work. We achieve career goals, gain happiness, and feel loved. Healthy, optimism, friendship, teamwork is what everyone here values, we are proud of our supportive and inclusive culture, and feel blessed to have such a strong and caring family to back us up. We have an incredibly exciting journey ahead of us."
        }
      ]
    }
  ]
}

// CONTACT US
export const CONTACT = {
  title: 'CONTACT US',
  email: 'hr@leaflongtx.com',
  ship: 'partnership@leaflongtx.com',
  address: '上海市杨浦区创智天地企业中心3号楼',
  addressNew: '杭州市余杭区浙富西溪堂6号楼'
}