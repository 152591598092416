import {useEffect, useState} from 'react';
import cs from 'classnames';
import { MinusOutline } from 'antd-mobile-icons'
import './index.scss';

const NAV_LIST = [
  {title: 'About Us', id: 'about', screenX: 0},
  {title: 'Science', id: 'science', screenX: 1550, children: [
    {title: 'Technology', id: 'technology', screenX: 1550},
    {title: 'Solution', id: 'solution', screenX: 2200},
  ]},
  {title: 'News Room', id: 'news', screenX: 2700},
  {title: 'Careers', id: 'careers', screenX: 2710, children: [
    {title: 'Our Values', id: 'values', screenX: 2850},
    {title: 'Our Culture', id: 'culture', screenX: 4000},
  ]},
  {title: 'Contact', id: 'contact', screenX: 4400},
]

const Header = () => {
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [selectId, setSelectId] = useState('');

  const moveTo = (value = 0) => {
    window.scrollTo(0, value)
  }

  useEffect(() => {
    window.onscroll = function() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if(scrollTop < 1450) {
        setSelectId('about')
      }

      if(1550 < scrollTop && scrollTop < 2200) {
        setSelectId('science')
      }

      if(2200 < scrollTop && scrollTop < 2710) {
        setSelectId('solution')
      }

      if(2710 < scrollTop && scrollTop < 2850) {
        setSelectId('news')
      }

      if(2850 < scrollTop && scrollTop < 4000) {
        setSelectId('careers')
      }

      if(4000 < scrollTop && scrollTop < 4400) {
        setSelectId('culture')
      }

      if(scrollTop > 4400) {
        setSelectId('contact')
      }
    }
  }, [])

  return (
    <header className='lfx-header'>
      <img src={require('@/assets/images/logo.jpg')} alt="" />
      {/* menu */}
      <ul onTouchStart={() => {
        setIsShowMenu(!isShowMenu)
      }}>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <nav style={isShowMenu ? {height: 'auto'} : {height: 0}}>
        {
          NAV_LIST.map((nav) => {
            const isHasChild = nav.children && nav.children.length > 0;

            return (
              <div
                onTouchStart={() => {
                  // if(isHasChild) return;
                  setSelectId(nav.id);
                  setIsShowMenu(false);
                  moveTo(nav.screenX);
                }}
                className={cs('lfx-header-nav', {'selected': selectId === nav.id})}
                key={nav.id}
              >
                {nav.title}
                {
                  nav.children && nav.children.map(child => {

                    return (
                      <div
                        className={cs('lfx-header-nav-child', {'selected': selectId === child.id})}
                        key={`${nav.id}_${child.id}`}
                        onTouchStart={(e) => {
                          e.stopPropagation();
                          setSelectId(child.id);
                          setIsShowMenu(false);
                          moveTo(child.screenX);
                        }}
                      >
                        {child.title}
                      </div>
                    )
                  })
                }
              </div>
            )
          })
        }
      </nav>
    </header>
  )
}


export default Header;