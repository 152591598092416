import { Routes, Route } from "react-router-dom";

import Layout from './Layout';
import Home from './Home';
import News from './News';
import About from './About';
import NoPage from './NoPage';



const App = () => {

  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='news' element={<News />} />
        <Route path='about' element={<About />} />
        <Route path="*" element={<NoPage />} />
      </Route>
    </Routes>
  )

}

export default App;
